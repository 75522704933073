import React from 'react';
import { useDarkMode } from '../contexts/DarkModeContext';

const Header = ({ toggleSidebar, user, onLogout }) => {
  const { darkMode, toggleDarkMode } = useDarkMode();
  return (
    <header className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} shadow-md`}>
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        <button onClick={toggleSidebar} className="focus:outline-none focus:text-gray-600">
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
        <div className="flex items-center">
          <button onClick={toggleDarkMode} className="mr-4 p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
            {darkMode ? '☀️' : '🌙'}
          </button>
          <span className="mr-4">Vincent</span>
          <button onClick={onLogout} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-300">
            Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;