import React, {useState, useEffect} from 'react';

const GameForm = ({game, onSubmit, onCancel, darkMode}) => {
    const [formData, setFormData] = useState({
        title: '',
        developer: '',
        publisher: '',
        release_date: '',
        genre: '',
        platform: '',
    });

    useEffect(() => {
        if (game) {
            setFormData(game);
        }
    }, [game]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    console.log('Rendering GameForm', {game, darkMode, formData});


    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label htmlFor="title"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Title
                </label>
                <input
                    type="text"
                    name="title"
                    id="title"
                    value={formData.title}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div>
                <label htmlFor="developer"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Developer
                </label>
                <input
                    type="text"
                    name="developer"
                    id="developer"
                    value={formData.developer}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div>
                <label htmlFor="publisher"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Publisher
                </label>
                <input
                    type="text"
                    name="publisher"
                    id="publisher"
                    value={formData.publisher}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div>
                <label htmlFor="release_date"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Release Date
                </label>
                <input
                    type="date"
                    name="release_date"
                    id="release_date"
                    value={formData.release_date}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div>
                <label htmlFor="genre"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Genre
                </label>
                <input
                    type="text"
                    name="genre"
                    id="genre"
                    value={formData.genre}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div>
                <label htmlFor="platform"
                       className={`block text-sm font-medium ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                    Platform
                </label>
                <input
                    type="text"
                    name="platform"
                    id="platform"
                    value={formData.platform}
                    onChange={handleChange}
                    className={`mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
                    required
                />
            </div>
            <div className="flex justify-end space-x-2">
                <button
                    type="button"
                    onClick={onCancel}
                    className={`py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium ${darkMode ? 'text-gray-300 bg-gray-700 hover:bg-gray-600' : 'text-gray-700 bg-white hover:bg-gray-50'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    Save
                </button>
            </div>
        </form>
    );
};

export default GameForm;