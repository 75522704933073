import React, {useState, useEffect} from 'react';
import {getGames, addGame, updateGame, deleteGame} from '../services/api';
import {useDarkMode} from '../contexts/DarkModeContext';
import Sidebar from './Sidebar';
import Header from './Header';
import DashboardOverview from './DashboardOverview';
import {toast} from 'react-toastify';
import VideoGameCard from './VideoGameCard';
import Modal from './Modal';
import GameForm from './GameForm';

const VideoGameCollection = () => {
    const [games, setGames] = useState([]);
    const [filteredGames, setFilteredGames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentGame, setCurrentGame] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const {darkMode, toggleDarkMode} = useDarkMode();

    useEffect(() => {
        fetchGames();
    }, []);

    const fetchGames = async () => {
        try {
            const response = await getGames();
            setGames(response.data);
            setFilteredGames(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching games:', error);
            setError('Failed to load games. Please try again later.');
            setLoading(false);
        }
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        const filtered = games.filter(game =>
            game.title.toLowerCase().includes(term.toLowerCase()) ||
            game.developer.toLowerCase().includes(term.toLowerCase()) ||
            game.genre.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredGames(filtered);
    };

    const handleAddGame = () => {
        setCurrentGame(null);
        setIsModalOpen(true);
    };

    const handleEditGame = (game) => {
        setCurrentGame(game);
        setIsModalOpen(true);
    };

    const RefreshButton = ({onRefresh}) => (
        <button
            onClick={onRefresh}
            className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
            Refresh Games
        </button>
    );

    const handleSubmitGame = async (gameData) => {
        try {
            if (gameData.id) {
                const updatedGame = await updateGame(gameData.id, gameData);
                setGames(games.map(game => game.id === updatedGame.id ? updatedGame : game));
                toast.success('Game updated successfully!');
            } else {
                const newGame = await addGame(gameData);
                setGames([...games, newGame]);
                toast.success('New game added successfully!');
            }
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving game:', error);
            toast.error('Failed to save game. Please try again.');
        }
    };
    console.log('Rendering VideoGameCollection', {isModalOpen, currentGame, darkMode});


    const handleDeleteGame = async (id) => {
        if (window.confirm('Are you sure you want to delete this game?')) {
            try {
                await deleteGame(id);
                const updatedGames = games.filter(game => game.id !== id);
                setGames(updatedGames);
                setFilteredGames(updatedGames);
            } catch (error) {
                console.error('Error deleting game:', error);
                setError('Failed to delete game. Please try again.');
            }
        }
    };

    const handleRefresh = async () => {
        try {
            const refreshedGames = await getGames();
            setGames(refreshedGames);
            setFilteredGames(refreshedGames);
            toast.info('Games refreshed successfully!', {
                position: "top-right",
                autoClose: 3000,
            });
        } catch (error) {
            console.error('Error refreshing games:', error);
            toast.error('Failed to refresh games. Please try again.', {
                position: "top-right",
                autoClose: 5000,
            });
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div
            className={`h-screen flex overflow-hidden ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-900'}`}>
            <Sidebar isOpen={sidebarOpen} toggleSidebar={() => setSidebarOpen(!sidebarOpen)} darkMode={darkMode}/>
            <div className="flex flex-col w-0 flex-1 overflow-hidden">
                <Header
                    toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                    darkMode={darkMode}
                    toggleDarkMode={toggleDarkMode}
                    searchTerm={searchTerm}
                    onSearch={handleSearch}
                />
                <main className="flex-1 relative overflow-y-auto focus:outline-none">
                    <div className="py-6">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <h1 className="text-2xl font-semibold">Dashboard</h1>
                        </div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                            <DashboardOverview games={games}/>
                            <div className="py-4">
                                <div className="flex justify-between items-center mb-4">
                                    <h2 className="text-xl font-semibold">Your Games</h2>
                                    <button
                                        onClick={handleAddGame}
                                        className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Add New Game
                                    </button>
                                    <RefreshButton onRefresh={handleRefresh}/>
                                </div>
                                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                                    {filteredGames.map((game) => (
                                        <VideoGameCard
                                            key={game.id}
                                            game={game}
                                            onEdit={() => handleEditGame(game)}
                                            onDelete={() => handleDeleteGame(game.id)}
                                            darkMode={darkMode}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                title={currentGame ? 'Edit Game' : 'Add New Game'}
                darkMode={darkMode}
            >
                {isModalOpen && (
                    <GameForm
                        game={currentGame}
                        onSubmit={handleSubmitGame}
                        onCancel={() => setIsModalOpen(false)}
                        darkMode={darkMode}
                    />
                )}
            </Modal>
        </div>
    );
};

export default VideoGameCollection;