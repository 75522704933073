import axios from 'axios';

const API_URL = 'https://videogames.keerok.tech/api';

// Replace 'YOUR_SUPERUSER_TOKEN' with the actual token for your superuser
const SUPERUSER_TOKEN = '62a0a7b711f5164379cf8945269a822ac1b4237e';

const apiService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Token ${SUPERUSER_TOKEN}`
  },
});

export const getGames = () => apiService.get('/games/');

export const addGame = (gameData) => apiService.post('/games/', gameData);

export const updateGame = (id, gameData) => apiService.put(`/games/${id}/`, gameData);

export const deleteGame = (id) => apiService.delete(`/games/${id}/`);


export const changePassword = async (currentPassword, newPassword) => {
  const response = await apiService.post('/change-password/', { currentPassword, newPassword });
  return response.data;
};

export const generateApiKey = async () => {
  const response = await apiService.post('/generate-api-key/');
  return response.data;
};


export default apiService;
