import React from 'react';

const VideoGameCard = ({ game, onEdit, onDelete, darkMode }) => (
  <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} overflow-hidden shadow rounded-lg`}>
    <div className="px-4 py-5 sm:p-6">
      <h3 className={`text-lg leading-6 font-medium ${darkMode ? 'text-white' : 'text-gray-900'}`}>{game.title}</h3>
      <div className="mt-2 max-w-xl text-sm text-gray-500">
        <p>Developer: {game.developer}</p>
        <p>Publisher: {game.publisher}</p>
        <p>Release Date: {game.release_date}</p>
        <p>Genre: {game.genre}</p>
        <p>Platform: {game.platform}</p>
      </div>
    </div>
    <div className={`px-4 py-4 sm:px-6 ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
      <button
        onClick={onEdit}
        className="mr-2 inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Edit
      </button>
      <button
        onClick={onDelete}
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Delete
      </button>
    </div>
  </div>
);

export default VideoGameCard;