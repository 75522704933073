import React from 'react';

const DashboardOverview = ({ games, darkMode }) => {
  const totalGames = games.length;
  const completionRate = 75; // This should be calculated based on your game data
  const favoriteGenre = "RPG"; // This should be determined from your game data

  return (
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white'} rounded-lg shadow-md p-6 mb-6`}>
      <h2 className="text-2xl font-semibold mb-4">Dashboard Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className={`${darkMode ? 'bg-blue-900' : 'bg-blue-100'} p-4 rounded-lg`}>
          <h3 className={`text-lg font-semibold ${darkMode ? 'text-blue-200' : 'text-blue-800'}`}>Total Games</h3>
          <p className={`text-3xl font-bold ${darkMode ? 'text-blue-100' : 'text-blue-600'}`}>{totalGames}</p>
        </div>
        <div className={`${darkMode ? 'bg-green-900' : 'bg-green-100'} p-4 rounded-lg`}>
          <h3 className={`text-lg font-semibold ${darkMode ? 'text-green-200' : 'text-green-800'}`}>Completion Rate</h3>
          <p className={`text-3xl font-bold ${darkMode ? 'text-green-100' : 'text-green-600'}`}>{completionRate}%</p>
        </div>
        <div className={`${darkMode ? 'bg-purple-900' : 'bg-purple-100'} p-4 rounded-lg`}>
          <h3 className={`text-lg font-semibold ${darkMode ? 'text-purple-200' : 'text-purple-800'}`}>Favorite Genre</h3>
          <p className={`text-3xl font-bold ${darkMode ? 'text-purple-100' : 'text-purple-600'}`}>{favoriteGenre}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardOverview;