import React from 'react';
import VideoGameCollection from './components/VideoGameCollection';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <div className="App">
            <VideoGameCollection/>
            <ToastContainer/>
        </div>
    );
}

export default App;